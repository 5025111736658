import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { loadQuery, RelayEnvironmentProvider } from 'react-relay';
import { RelayEnvironment } from '../relay/RelayEnvironment';
import '../../../stylesheets/components/globals.module.scss';
import '../../../stylesheets/components/theme.module.scss';
import '../../../stylesheets/components/buttonGlobals.module.scss';
import '../../../stylesheets/components/dropdownMenus.module.scss';
import '../../../stylesheets/components/dialog.module.scss';
import Email from './Email';
import Options from './Options';
import ActivateEmail, {
  EmailQuery as ActivateEmailQuery,
} from './activate/Email';
import Activate, { ActivateGlimmer, ActivateQuery } from './activate/Activate';
import ActivateOptions from './activate/Options';
import ForgotPassword from './ForgotPassword';
import ChangePassword, {
  ChangePasswordGlimmer,
  ChangePasswordQuery,
} from './ChangePassword';
import RequestLink, { RequestLinkQuery } from './link/RequestLink';
import LinkAccount, { LinkAccountQuery } from './link/LinkAccount';
import { ThemeContextProvider } from '../components/ThemeContextProvider';
import LoginBody from './LoginBody';
import PageHeader from '../tenant_portal/PageHeader';
import Logout from './Logout';
import { LoginHeaderQuery } from './LoginHeader';
import ChooseRole, { ChooseRoleGlimmer, ChooseRoleQuery } from './ChooseRole';
import loginStyles from '../../../stylesheets/components/login.module.scss';

const rootElement: Element | null = document.querySelector('#root');

const Login = () => {
  return (
    <ThemeContextProvider isLoggedIn={false}>
      <main className={loginStyles.loginWrap}>
        <LoginBody>
          <Outlet />
        </LoginBody>
      </main>
    </ThemeContextProvider>
  );
};

const Root = () => {
  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <Login />
    </RelayEnvironmentProvider>
  );
};

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Root />,
    loader: () => {
      const hostParts = window.location.host.split('.');
      let subdomain = '';
      if (hostParts.length > 2) {
        const sd = hostParts[0];
        if (sd !== 'www' && sd !== 'app') subdomain = sd;
      }
      return loadQuery(RelayEnvironment, LoginHeaderQuery, {
        subdomain: subdomain,
      });
    },
    children: [
      {
        index: true,
        element: (
          <>
            <PageHeader
              title="Welcome back"
              dataTestClass="login-page-header"
            />
            <Options emailPath="/login/email" />
          </>
        ),
      },
      {
        path: 'email',
        element: <Email />,
      },
      {
        path: 'link/:oauthLinkActivationToken',
        element: <RequestLink />,
        loader: ({ params }) => {
          return loadQuery(RelayEnvironment, RequestLinkQuery, {
            token: params.oauthLinkActivationToken,
          });
        },
      },
      {
        path: 'link/link-account/:oauthLinkActivationToken',
        element: <LinkAccount />,
        loader: ({ params }) => {
          return loadQuery(RelayEnvironment, LinkAccountQuery, {
            token: params.oauthLinkActivationToken,
          });
        },
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'change-password/:resetPasswordToken',
        element: (
          <Suspense fallback={<ChangePasswordGlimmer />}>
            <ChangePassword />
          </Suspense>
        ),
        loader: ({ params }) => {
          return loadQuery(RelayEnvironment, ChangePasswordQuery, {
            token: params.resetPasswordToken,
          });
        },
      },
      {
        path: 'activate/:activationKey',
        element: (
          <Suspense fallback={<ActivateGlimmer />}>
            <Activate />
          </Suspense>
        ),
        children: [
          {
            path: '',
            element: <ActivateOptions />,
          },
          {
            path: 'email',
            element: <ActivateEmail />,
            loader: ({ params }) => {
              return loadQuery(RelayEnvironment, ActivateEmailQuery, {
                key: params.activationKey,
              });
            },
          },
        ],
        loader: ({ params }) => {
          return loadQuery(RelayEnvironment, ActivateQuery, {
            key: params.activationKey,
          });
        },
      },
      { path: 'log-out', element: <Logout /> },
      {
        path: 'choose-role',
        element: (
          <Suspense fallback={<ChooseRoleGlimmer />}>
            <ChooseRole />
          </Suspense>
        ),
        loader: () => {
          return loadQuery(RelayEnvironment, ChooseRoleQuery, {});
        },
      },
    ],
  },
]);

const App = () => <RouterProvider router={router} />;
if (rootElement) {
  createRoot(rootElement).render(<App />);
}
